import React, { useEffect, useState } from 'react'
import { formatCurrency } from '../../utils'
import http from '../../utils/http'
import Chart from '../Chart/Chart'
import Header from '../Header/Header'
import { useMediaQuery } from 'react-responsive'

const Dashboard = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 })

  const [totalRevenue, setTotalRevenue] = useState(0)
  const [totalTransactions, setTotalTransactions] = useState(0)
  const [totalRevenueToday, setTotalRevenueToday] = useState(0)
  const [totalTransactionsToday, setTotalTransactionsToday] = useState(0)
  const [dataTotalRevenue,setDataTotalRevenue] = useState([])
  const [dataTodayTotal, setDataTodayTotal] = useState([])

  const getTotalRevenueByKiosk = async (kioskid) => {
    try {
      const resp = await http.post('/dashboard',{"kioskId":kioskid})
      setDataTotalRevenue((prev) => [...prev, resp.data])
    } catch (error) {
      console.log('error', error)
    }
  }

  const getTodayTotalByKiosk = async (kioskid) => {
    try {
      const resp = await http.post('/dashboard/day',{"kioskId":kioskid})
      setDataTodayTotal((prev) => [...prev, resp.data])
    } catch (error) {
      console.log('error', error)
    }
  }


  // resonseTotal ,  
  useEffect(() => {
    ;(async () => {
        try {
          const kiosktotal = sessionStorage.getItem('kioskTotal')
          const dashboardload = sessionStorage.getItem('totalrev')        
  
          if (dashboardload == null)
            {
              for (var i = 0; i < kiosktotal; i++) {
                  const kioskid =  sessionStorage.getItem('kioskId'+i)
                  getTotalRevenueByKiosk(kioskid)
                }
                sessionStorage.setItem('totalrev','0')
            }
        } catch (error) {
          console.log('error: ', error)
        }
    })()
    ;(async () => {
      try {
      const kiosktotal = sessionStorage.getItem('kioskTotal')        
      const dashboardload = sessionStorage.getItem('totalToday')        
  
      if (dashboardload == null)
        {
          for (var i = 0; i < kiosktotal; i++) {
              const kioskid =  sessionStorage.getItem('kioskId'+i)
              getTodayTotalByKiosk(kioskid)
            }
            sessionStorage.setItem('totalToday','0')
        }
      } catch (error) {
      console.log('error: ', error)
      }

    })()
  }, [])

  useEffect(()=> {
    if (dataTotalRevenue.length !== 0)
    {
      let totalrev = 0
      let totalTrans = 0
      dataTotalRevenue.forEach((abc) => {
        totalrev += abc.totalRevenue
        totalTrans += abc.totalTransactions
      })

      setTotalRevenue(totalrev)
      setTotalTransactions(totalTrans)
      sessionStorage.removeItem('totalrev')
    }
  }, [dataTotalRevenue])

  useEffect(()=> {
    if ( dataTodayTotal.length !==0)
    {
      
      let todayTotal = 0
      let todayTrans = 0
      dataTodayTotal.forEach((abc) => {
        todayTotal += abc.totalRevenue
        todayTrans += abc.totalTransactions
      })

      setTotalRevenueToday(todayTotal)
      setTotalTransactionsToday(todayTrans)  
      sessionStorage.removeItem('totalToday')

    }
  }, [dataTodayTotal])

  return (
    <div style={{ padding: '20px' }}>
      {isMobile && <Header />}
      <div
        className="d-flex align-items-center justify-content-between dashboard"
        style={
          isMobile
            ? {
                marginTop: '55px',
                fontSize: '16px',
                textAlign: 'center',
                gap: '20px'
              }
            : {
                marginTop: '1rem',
                fontSize: '16px',
                textAlign: 'center',
                gap: '20px'
              }
        }
      >
        <div
          className={'col-item'}
          style={{
            backgroundImage: 'linear-gradient(310deg,#f5365c,#f56036)',
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
          }}
          span={5}
        >
          <div>
            <p style={{ fontSize: '18px', fontWeight: '500' }}>
              <img src="/assets/images/money.png" alt="money" width={40} />
              <span className="mx-3" style={{ fontWeight: 'bold' }}>
                TỔNG DOANH THU
              </span>
            </p>
            <p style={{ fontSize: '30px', fontWeight: 'bold' }}>
              {formatCurrency(totalRevenue)}
            </p>
          </div>
        </div>

        <div
          className={'col-item'}
          style={{
            backgroundImage: 'linear-gradient(310deg,#fb6340,#fbb140)',
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
          }}
          span={5}
        >
          <div>
            <p style={{ fontSize: '18px', fontWeight: '500' }}>
              <img
                src="/assets/images/transaction.png"
                alt="transaction"
                width={40}
              />
              <span className="mx-2" style={{ fontWeight: 'bold' }}>
                TỔNG GIAO DỊCH
              </span>
            </p>
            <p style={{ fontSize: '30px', fontWeight: 'bold' }}>
              {totalTransactions}
            </p>
          </div>
        </div>

        <div
          className={'col-item'}
          style={{
            backgroundImage: 'linear-gradient(310deg,#2dce89,#2dcecc)',
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
          }}
          span={5}
        >
          <div>
            <p style={{ fontSize: '18px', fontWeight: '500' }}>
              <img src="/assets/images/money.png" alt="money" width={40} />
              <span className="mx-3" style={{ fontWeight: 'bold' }}>
                DOANH THU HÔM NAY
              </span>
            </p>
            <p style={{ fontSize: '30px', fontWeight: 'bold' }}>
              {formatCurrency(totalRevenueToday)}
            </p>
          </div>
        </div>

        <div
          className={'col-item'}
          style={{
            backgroundImage: 'linear-gradient(310deg,#5e72e4,#825ee4)',
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
          }}
          span={5}
        >
          <div>
            <p style={{ fontSize: '18px', fontWeight: '500' }}>
              <img
                src="/assets/images/transaction.png"
                alt="transaction"
                width={50}
              />
              <span className="mx-2" style={{ fontWeight: 'bold' }}>
                GIAO DỊCH HÔM NAY
              </span>
            </p>
            <p style={{ fontSize: '30px', fontWeight: 'bold' }}>
              {totalTransactionsToday}
            </p>
          </div>
        </div>
      </div>
      <Chart />
    </div>
  )
}

export default Dashboard
