import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { path } from '../../constants/path'
import { useLocation } from 'react-router-dom'
import { AuthContext, useAuth } from '../../context/AuthContext'
import { useMediaQuery } from 'react-responsive'

const Sidebar = () => {
  const navigate = useNavigate()
  const { setAuthenticated } = useAuth()
  const { openDrawer, setOpenDrawer } = useContext(AuthContext)
  const [vmc, setVmc] = useState(0)
  const location = useLocation()
  const currentURI = location.pathname
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const isHideLockerSetting = true
  // get sl mbh

  // const DynamicTag = ({ tag, children, ...props }) => {
  //   const Tag = tag || 'div' // Default to 'div' if no tag is specified
  //   return <Tag {...props}>{children}</Tag>
  // }
  const Item = ({ kioskname }) => (
    <a class="menu-link">
      <span class="menu-bullet">
        <span class="bullet bullet-dot"></span>
      </span>
      <span class="menu-title">{kioskname}</span>
    </a>
  )
  const kiosktotal = sessionStorage.getItem('kioskTotal')

  const createsbVMC = () => {
    const itemList = []
    for (let i = 0; i < kiosktotal; i++) {
      const kioskname = sessionStorage.getItem('kioskName' + i)
      itemList.push(kioskname)
    }
    return itemList
  }

  // }

  // <div class="menu-item">
  //   <a class="menu-link">
  //     <span class="menu-bullet">
  //       <span class="bullet bullet-dot"></span>
  //     </span>
  //     <span class="menu-title">{kioskname}</span>
  //   </a>
  // </div>

  return (
    <div
      style={isMobile ? { width: '250px' } : {}}
      id="kt_aside"
      className={`aside aside-dark aside-hoverable ${
        isMobile ? 'drawer drawer-start' : ''
      } ${openDrawer ? 'drawer-on' : ''}`}
      data-kt-drawer="true"
      data-kt-drawer-name="aside"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-direction="start"
      data-kt-drawer-toggle="#kt_aside_mobile_toggle"
    >
      <div className="aside-logo flex-column-auto" id="kt_aside_logo">
        <a
          style={{
            fontSize: 20,
            fontWeight: 'bold',
            textAlign: 'center'
          }}
          href="#logo"
          className="text-white"
        >
          QUẢN LÝ TSE MACHINE
        </a>
      </div>
      <div className="aside-menu flex-column-fluid">
        <div
          className="hover-scroll-overlay-y my-5 my-lg-5"
          id="kt_aside_menu_wrapper"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-height="auto"
          data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
          data-kt-scroll-wrappers="#kt_aside_menu"
          data-kt-scroll-offset={0}
        >
          <div
            className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
            id="#kt_aside_menu"
            data-kt-menu="true"
            data-kt-menu-expand="false"
          >
            <div
              data-kt-menu-trigger="click"
              className={`menu-item menu-accordion ${
                currentURI === path.home ? 'here show' : ''
              }`}
              onClick={() => {
                setOpenDrawer(false)
                navigate(path.home)
              }}
            >
              <span className="menu-link">
                <span className="menu-icon">
                  <span className="svg-icon svg-icon-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        x={2}
                        y={2}
                        width={9}
                        height={9}
                        rx={2}
                        fill="currentColor"
                      />
                      <rect
                        opacity="0.3"
                        x={13}
                        y={2}
                        width={9}
                        height={9}
                        rx={2}
                        fill="currentColor"
                      />
                      <rect
                        opacity="0.3"
                        x={13}
                        y={13}
                        width={9}
                        height={9}
                        rx={2}
                        fill="currentColor"
                      />
                      <rect
                        opacity="0.3"
                        x={2}
                        y={13}
                        width={9}
                        height={9}
                        rx={2}
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </span>
                <span className="menu-title">TỔNG QUAN</span>
              </span>
            </div>

            <div
              data-kt-menu-trigger="click"
              className={`menu-item menu-accordion ${
                currentURI === path.transactions ? 'here show' : ''
              }`}
              onClick={() => {
                setOpenDrawer(false)
                navigate(path.transactions)
              }}
            >
              <span className="menu-link">
                <span className="menu-icon">
                  <span className="svg-icon svg-icon-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        opacity="0.3"
                        d="M20 15H4C2.9 15 2 14.1 2 13V7C2 6.4 2.4 6 3 6H21C21.6 6 22 6.4 22 7V13C22 14.1 21.1 15 20 15ZM13 12H11C10.5 12 10 12.4 10 13V16C10 16.5 10.4 17 11 17H13C13.6 17 14 16.6 14 16V13C14 12.4 13.6 12 13 12Z"
                        fill="currentColor"
                      />
                      <path
                        d="M14 6V5H10V6H8V5C8 3.9 8.9 3 10 3H14C15.1 3 16 3.9 16 5V6H14ZM20 15H14V16C14 16.6 13.5 17 13 17H11C10.5 17 10 16.6 10 16V15H4C3.6 15 3.3 14.9 3 14.7V18C3 19.1 3.9 20 5 20H19C20.1 20 21 19.1 21 18V14.7C20.7 14.9 20.4 15 20 15Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </span>
                <span className="menu-title">GIAO DỊCH</span>
              </span>
            </div>

            {!isHideLockerSetting && !isMobile && (
              <div
                data-kt-menu-trigger="click"
                className={`menu-item menu-accordion ${
                  currentURI === path.products ? 'here show' : ''
                }`}
                onClick={() => {
                  setOpenDrawer(false)
                  navigate(path.products)
                }}
              >
                <span className="menu-link">
                  <span className="menu-icon">
                    <span className="svg-icon svg-icon-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M21 10H13V11C13 11.6 12.6 12 12 12C11.4 12 11 11.6 11 11V10H3C2.4 10 2 10.4 2 11V13H22V11C22 10.4 21.6 10 21 10Z"
                          fill="currentColor"
                        />
                        <path
                          opacity="0.3"
                          d="M12 12C11.4 12 11 11.6 11 11V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V11C13 11.6 12.6 12 12 12Z"
                          fill="currentColor"
                        />
                        <path
                          opacity="0.3"
                          d="M18.1 21H5.9C5.4 21 4.9 20.6 4.8 20.1L3 13H21L19.2 20.1C19.1 20.6 18.6 21 18.1 21ZM13 18V15C13 14.4 12.6 14 12 14C11.4 14 11 14.4 11 15V18C11 18.6 11.4 19 12 19C12.6 19 13 18.6 13 18ZM17 18V15C17 14.4 16.6 14 16 14C15.4 14 15 14.4 15 15V18C15 18.6 15.4 19 16 19C16.6 19 17 18.6 17 18ZM9 18V15C9 14.4 8.6 14 8 14C7.4 14 7 14.4 7 15V18C7 18.6 7.4 19 8 19C8.6 19 9 18.6 9 18Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </span>
                  <span className="menu-title">CẤU HÌNH SẢN PHẨM</span>
                  <span class="menu-arrow"></span>
                </span>
                <div class="menu-sub menu-sub-accordion menu-active-bg">
                  {createsbVMC().map((item, index) => (
                    <Item kioskname={item} />
                  ))}
                </div>
              </div>
            )}

            {!isHideLockerSetting && !isMobile && (
              <div
                data-kt-menu-trigger="click"
                className={`menu-item menu-accordion
                 ${currentURI === path.setting ? 'here show' : ''}`}
                onClick={() => {
                  setOpenDrawer(false)
                  navigate(path.setting)
                }}
              >
                <span className="menu-link">
                  <span className="menu-icon">
                    <span className="svg-icon svg-icon-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path d="M22 7H2V11H22V7Z" fill="currentColor" />
                        <path
                          opacity="0.3"
                          d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19ZM14 14C14 13.4 13.6 13 13 13H5C4.4 13 4 13.4 4 14C4 14.6 4.4 15 5 15H13C13.6 15 14 14.6 14 14ZM16 15.5C16 16.3 16.7 17 17.5 17H18.5C19.3 17 20 16.3 20 15.5C20 14.7 19.3 14 18.5 14H17.5C16.7 14 16 14.7 16 15.5Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </span>
                  <span className="menu-title">CẤU HÌNH MBH</span>
                  <span class="menu-arrow"></span>
                </span>
              </div>
            )}
            {!isHideLockerSetting && !isMobile && (
              <div
                data-kt-menu-trigger="click"
                className={`menu-item menu-accordion
                 ${currentURI === path.cms ? 'here show' : ''}`}
                onClick={() => {
                  setOpenDrawer(false)
                  navigate(path.cms)
                }}
              >
                {' '}
                <span class="menu-link">
                  <span class="menu-icon">
                    <span class="svg-icon svg-icon-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M13 5.91517C15.8 6.41517 18 8.81519 18 11.8152C18 12.5152 17.9 13.2152 17.6 13.9152L20.1 15.3152C20.6 15.6152 21.4 15.4152 21.6 14.8152C21.9 13.9152 22.1 12.9152 22.1 11.8152C22.1 7.01519 18.8 3.11521 14.3 2.01521C13.7 1.91521 13.1 2.31521 13.1 3.01521V5.91517H13Z"
                          fill="currentColor"
                        />
                        <path
                          opacity="0.3"
                          d="M19.1 17.0152C19.7 17.3152 19.8 18.1152 19.3 18.5152C17.5 20.5152 14.9 21.7152 12 21.7152C9.1 21.7152 6.50001 20.5152 4.70001 18.5152C4.30001 18.0152 4.39999 17.3152 4.89999 17.0152L7.39999 15.6152C8.49999 16.9152 10.2 17.8152 12 17.8152C13.8 17.8152 15.5 17.0152 16.6 15.6152L19.1 17.0152ZM6.39999 13.9151C6.19999 13.2151 6 12.5152 6 11.8152C6 8.81517 8.2 6.41515 11 5.91515V3.01519C11 2.41519 10.4 1.91519 9.79999 2.01519C5.29999 3.01519 2 7.01517 2 11.8152C2 12.8152 2.2 13.8152 2.5 14.8152C2.7 15.4152 3.4 15.7152 4 15.3152L6.39999 13.9151Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </span>
                  <span class="menu-title">CẤU HÌNH QUẢN TRỊ</span>
                  <span class="menu-arrow"></span>
                </span>
                <div class="menu-sub menu-sub-accordion">
                  <div class="menu-item">
                    <a class="menu-link">
                      <span class="menu-bullet">
                        <span class="bullet bullet-dot"></span>
                      </span>
                      <span class="menu-title">QUẢN LÝ NGƯỜI DÙNG</span>
                    </a>
                  </div>
                  <div
                    data-kt-menu-trigger="click"
                    class="menu-item menu-accordion mb-1"
                  >
                    <span class="menu-link">
                      <span class="menu-bullet">
                        <span class="bullet bullet-dot"></span>
                      </span>
                      <span class="menu-title">QUẢN LÝ LỖI</span>
                    </span>
                  </div>
                  <div
                    data-kt-menu-trigger="click"
                    class="menu-item menu-accordion mb-1"
                  >
                    <span class="menu-link">
                      <span class="menu-bullet">
                        <span class="bullet bullet-dot"></span>
                      </span>
                      <span class="menu-title">HƯỚNG DẪN SỬ DỤNG</span>
                      <span class="menu-arrow"></span>
                    </span>
                    <div class="menu-sub menu-sub-accordion">
                      <div class="menu-item">
                        <a class="menu-link">
                          <span class="menu-bullet">
                            <span class="bullet bullet-dot"></span>
                          </span>
                          <span class="menu-title">Tutorials List</span>
                        </a>
                      </div>
                      <div class="menu-item">
                        <a class="menu-link">
                          <span class="menu-bullet">
                            <span class="bullet bullet-dot"></span>
                          </span>
                          <span class="menu-title">Tutorial Post</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div
              data-kt-menu-trigger="click"
              className="menu-item menu-accordion"
              onClick={() => {
                localStorage.removeItem('ACCESS_TOKEN')
                setAuthenticated(false)
                sessionStorage.removeItem('totalrev')
                sessionStorage.removeItem('totalToday')
                sessionStorage.removeItem('transload')
                sessionStorage.removeItem('transloadpost')
                sessionStorage.clear()
              }}
            >
              <span className="menu-link">
                <span className="menu-icon">
                  <span className="svg-icon svg-icon-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        opacity="0.3"
                        d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                        fill="currentColor"
                      />
                      <path
                        d="M14.854 11.321C14.7568 11.2282 14.6388 11.1818 14.4998 11.1818H14.3333V10.2272C14.3333 9.61741 14.1041 9.09378 13.6458 8.65628C13.1875 8.21876 12.639 8 12 8C11.361 8 10.8124 8.21876 10.3541 8.65626C9.89574 9.09378 9.66663 9.61739 9.66663 10.2272V11.1818H9.49999C9.36115 11.1818 9.24306 11.2282 9.14583 11.321C9.0486 11.4138 9 11.5265 9 11.6591V14.5227C9 14.6553 9.04862 14.768 9.14583 14.8609C9.24306 14.9536 9.36115 15 9.49999 15H14.5C14.6389 15 14.7569 14.9536 14.8542 14.8609C14.9513 14.768 15 14.6553 15 14.5227V11.6591C15.0001 11.5265 14.9513 11.4138 14.854 11.321ZM13.3333 11.1818H10.6666V10.2272C10.6666 9.87594 10.7969 9.57597 11.0573 9.32743C11.3177 9.07886 11.6319 8.9546 12 8.9546C12.3681 8.9546 12.6823 9.07884 12.9427 9.32743C13.2031 9.57595 13.3333 9.87594 13.3333 10.2272V11.1818Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </span>
                <span className="menu-title">ĐĂNG XUẤT</span>
              </span>
            </div>
            <div class="menu-item">
              <a class="menu-link">
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M16.95 18.9688C16.75 18.9688 16.55 18.8688 16.35 18.7688C15.85 18.4688 15.75 17.8688 16.05 17.3688L19.65 11.9688L16.05 6.56876C15.75 6.06876 15.85 5.46873 16.35 5.16873C16.85 4.86873 17.45 4.96878 17.75 5.46878L21.75 11.4688C21.95 11.7688 21.95 12.2688 21.75 12.5688L17.75 18.5688C17.55 18.7688 17.25 18.9688 16.95 18.9688ZM7.55001 18.7688C8.05001 18.4688 8.15 17.8688 7.85 17.3688L4.25001 11.9688L7.85 6.56876C8.15 6.06876 8.05001 5.46873 7.55001 5.16873C7.05001 4.86873 6.45 4.96878 6.15 5.46878L2.15 11.4688C1.95 11.7688 1.95 12.2688 2.15 12.5688L6.15 18.5688C6.35 18.8688 6.65 18.9688 6.95 18.9688C7.15 18.9688 7.35001 18.8688 7.55001 18.7688Z"
                        fill="currentColor"
                      />
                      <path
                        opacity="0.3"
                        d="M10.45 18.9687C10.35 18.9687 10.25 18.9687 10.25 18.9687C9.75 18.8687 9.35 18.2688 9.55 17.7688L12.55 5.76878C12.65 5.26878 13.25 4.8687 13.75 5.0687C14.25 5.1687 14.65 5.76878 14.45 6.26878L11.45 18.2688C11.35 18.6688 10.85 18.9687 10.45 18.9687Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </span>
                <span class="menu-title">Power by TSE v8.0.25</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
