import React, { useState, useEffect, useRef } from 'react'
import ApexCharts from 'apexcharts'
import { formatCurrency } from '../../utils'
import {
  annualChart,
  dailyChart,
  weeklyChart,
  monthlyChart
} from '../../constants/chart'
import http from '../../utils/http'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { isSameDay } from 'date-fns';

const optionsFilter = [
  { label: 'Ngày', value: 'day', categories: dailyChart },
  { label: 'Tuần', value: 'week', categories: weeklyChart },
  { label: 'Tháng', value: 'month', categories: monthlyChart },
  { label: 'Năm', value: 'year', categories: annualChart }
]

const getDataYChartTransactionsByDay = (transactions) => {
  const hourlyTotals = {}

  transactions.forEach((transaction) => {
    const paymentTime = new Date(transaction.createdAt)
    const hour = paymentTime.getHours()

    if (!hourlyTotals[hour]) {
      hourlyTotals[hour] = 0
    }

    const amountPayment = parseFloat(transaction.paymentAmount)
    hourlyTotals[hour] += amountPayment
  })

  const result = []

  for (let hour = 0; hour < 24; hour++) {
    const totalAmount = hourlyTotals[hour] || 0
    result.push(totalAmount)
  }

  return result
}

const getDataYChartTransactionsByWeek = (transactions) => {
  const dailyTotals = {}
  transactions.forEach((transaction) => {
    const paymentDay = moment(transaction.createdAt).isoWeekday() - 1
    const amountPayment = parseFloat(transaction.paymentAmount) || 0

    if (!dailyTotals[paymentDay]) {
      dailyTotals[paymentDay] = 0
    }

    dailyTotals[paymentDay] += amountPayment
  })

  const result = []
  for (let day = 0; day < 7; day++) {
    const totalAmount = dailyTotals[day] || 0
    result.push(totalAmount)
  }

  return result
}

const getDataYChartTransactionsByMonth = (transactions) => {
  const dailyTotals = {}
  transactions.forEach((transaction) => {
    const paymentDay = moment(transaction.createdAt).date()
    const amountPayment = parseFloat(transaction.paymentAmount) || 0

    if (!dailyTotals[paymentDay]) {
      dailyTotals[paymentDay] = 0
    }

    dailyTotals[paymentDay] += amountPayment
  })

  const result = []
  for (let day = 1; day <= moment().daysInMonth(); day++) {
    const totalAmount = dailyTotals[day] || 0
    result.push(totalAmount)
  }

  return result
}

const getDataYChartTransactionsByYear = (transactions) => {
  const monthlyTotals = {}

  transactions.forEach((transaction) => {
    const paymentMonth = moment(transaction.createdAt).month()

    const amountPayment = parseFloat(transaction.paymentAmount) || 0

    if (!monthlyTotals[paymentMonth]) {
      monthlyTotals[paymentMonth] = 0
    }

    monthlyTotals[paymentMonth] += amountPayment
  })

  const result = []
  for (let month = 0; month < 12; month++) {
    const totalAmount = monthlyTotals[month] || 0
    result.push(totalAmount)
  }

  return result
}

const Chart = () => {
  const [filterChart, setFilterChart] = useState('day')
  const chartRef = useRef(null)
  const [transactions, setTransactions] = useState([])
  const [filterDate, setFilterDate] = useState(new Date())

  const getListTransactionByDate = async (date) => {
    const formattedDate = moment(date).format('DD/MM/YYYY')
    const kiosktotal = sessionStorage.getItem('kioskTotal')
    const transload = sessionStorage.getItem('transload')
      setTransactions([])

      for (var i = 0; i < kiosktotal; i++) {
        const kioskId = sessionStorage.getItem('kioskId' + i)
        const response = await http.post(`/dashboard/day/${formattedDate}`, {
          kioskId: kioskId
        })
        if (response.data.totalTransactions > 0){
          const transArr = []
          console.log('setTransactions 2',response.data.transactions)
          response.data.transactions.forEach((item) => {
            transArr.push(item)
          })
          setTransactions(transArr)
        }
      }
    
  }

  useEffect(() => {
    if (transactions.length !== 0) {
      //sessionStorage.removeItem('transload')
    }
  }, [transactions])

  useEffect(() => {
    const savedDate = sessionStorage.getItem('filterDate');
    if( isSameDay(savedDate, filterDate))
      return;
    sessionStorage.setItem('filterDate',filterDate)
    getListTransactionByDate(filterDate)
  }, [filterDate])

  useEffect(() => {
    ;(async () => {
      try {
        const month = moment().month() + 1
        const year = moment().year()
        const kiosktotal = sessionStorage.getItem('kioskTotal')
        const transload = sessionStorage.getItem('transload')
        let i = 0
        switch (filterChart) {
          case 'day': {
            if (true) {
              sessionStorage.setItem('transload', '1')
              for (i = 0; i < kiosktotal; i++) {
                const kioskId = sessionStorage.getItem('kioskId' + i)
                const response = await http.post(`/dashboard/day`, {
                  kioskId: kioskId
                })

                if (response.data.totalTransactions > 0){
                  const transArr = []
                  response.data.transactions.forEach((item) => {
                    transArr.push(item)
                  })
                  setTransactions(transArr)
                }
              }
            }
            break
          }
          case 'week': {
            const transArr = [];
            setTransactions([])
            if (true) {
              for (i = 0; i < kiosktotal; i++) {
                const kioskId = sessionStorage.getItem('kioskId' + i)
                const response = await http.post(
                  `/dashboard/month/${month}/${year}`,
                  {
                    kioskId: kioskId
                  }
                )
                const currentDate = moment()
                const startOfWeek = moment(currentDate).startOf('isoWeek')
                const endOfWeek = moment(currentDate).endOf('isoWeek')
                const transactionsThisWeek = response.data.transactions.filter(
                  (transaction) => {
                    const transactionDate = moment(transaction.createdAt)
                    return transactionDate.isBetween(startOfWeek, endOfWeek)
                  }
                )

                if (transactionsThisWeek.length > 0){
                  transactionsThisWeek.forEach((item) => {
                    transArr.push(item)
                  })
                }
              }
            }
            setTransactions(transArr)
            break
          }
          case 'month': {
            if (true) {
              for (i = 0; i < kiosktotal; i++) {
                const kioskId = sessionStorage.getItem('kioskId' + i)
                const response = await http.post(
                  `/dashboard/month/${month}/${year}`,
                  {
                    kioskId: kioskId
                  }
                )

                if (response.data.totalTransactions > 0){
                  const transArr = []
                  response.data.transactions.forEach((item) => {
                    transArr.push(item)
                  })
                  setTransactions(transArr)
                }
              }
            }
            break
          }
          case 'year': {
            const currentYear = new Date().getFullYear()

            if (true) {
              for (i = 0; i < kiosktotal; i++) {
                const kioskId = sessionStorage.getItem('kioskId' + i)
                const response = await http.post(
                  `/dashboard/year/${currentYear}`,
                  {
                    kioskId: kioskId
                  }
                )

                if (response.data.totalTransactions > 0){
                  const transArr = []
                  response.data.transactions.forEach((item) => {
                    transArr.push(item)
                  })
                  setTransactions(transArr)
                }
              }
            }
            break
          }
          default:
            setTransactions([])
        }
      } catch (error) {
        console.log('error: ', error)
      }
    })()
  }, [filterChart])

  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(450)
    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, optionsFilter, filterChart, transactions)
    )
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, filterChart, transactions])

  return (
    <div
      className="card"
      style={{
        fontSize: '16px',
        textAlign: 'center',
        gap: '20px',
        width: '100%'
      }}
    >
      <div className="card-header border-0 pt-5">
        <div>
          <h3 className="card-title align-items-start d-flex align-items-center">
            <span className="card-label fw-bolder fs-3 mb-1">Doanh Thu:</span>
            <span
              className="text-danger"
              style={{
                fontWeight: 'bold'
              }}
            >
              {
              formatCurrency(
                transactions.reduce((acc, val) => {
                  return acc + val.paymentAmount
                }, 0)
              )}
            </span>
          </h3>
          <h3 className="card-title align-items-start d-flex align-items-center">
            <span className="card-label fw-bolder fs-3 mb-1">
              Số giao dịch:
            </span>
            <span
              className="text-danger"
              style={{
                fontWeight: 'bold'
              }}
            >
              {transactions.length}
            </span>
          </h3>
        </div>

        <div className="card-toolbar" data-kt-buttons="true">
          {optionsFilter.map((op) => (
            <p
              key={op.value}
              onClick={() => {
                setFilterDate(new Date())
                setFilterChart(op.value)
              }}
              className={`btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1 ${
                op.value === filterChart ? 'active' : ''
              }`}
            >
              {op.label}
            </p>
          ))}
        </div>

        <div className="me-4 my-1 d-flex align-items-center filter-item">
          <span style={{ fontSize: '12px', marginRight: 10 }}>
            Ngày giao dịch:
          </span>
          <DatePicker
            className="w-125px"
            dateFormat="dd/MM/yyyy"
            selected={filterDate}
            onChange={(date) => {
              sessionStorage.removeItem('filterDate')
              console.log('onDate picker changed')
              setFilterDate(date)
            }}
          />
          <img
            onClick={() => 
              {
                sessionStorage.removeItem('filterDate')
                setFilterDate(new Date())
              }}
            style={{ marginLeft: '6px' }}
            className="cursor-pointer filter-reset"
            src="/assets/images/close.png"
            alt="lisence-plate"
            width={16}
          />
        </div>
      </div>

      <div className="card-body dashboard-chart">
        <div ref={chartRef} style={{ height: '350px' }}></div>
      </div>
    </div>
  )
}

export default Chart

function getCSSVariableValue(variableName) {
  let hex = getComputedStyle(document.documentElement).getPropertyValue(
    variableName
  )
  if (hex && hex.length > 0) {
    hex = hex.trim()
  }

  return hex
}

const getChartOptions = (
  height,
  optionsFilter,
  filterChart,
  transactions = []
) => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = '#00aefd'
  const secondaryColor = getCSSVariableValue('--bs-gray-300')

  let formatTransactions = []
  if (filterChart === 'day') {
    formatTransactions = getDataYChartTransactionsByDay(transactions)
  } else if (filterChart === 'week') {
    formatTransactions = getDataYChartTransactionsByWeek(transactions)
  } else if (filterChart === 'month') {
    formatTransactions = getDataYChartTransactionsByMonth(transactions)
  } else if (filterChart === 'year') {
    formatTransactions = getDataYChartTransactionsByYear(transactions)
  }

  return {
    series: [
      {
        name: 'Doanh thu',
        data: formatTransactions
      }
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
        borderRadius: 5
      }
    },
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: optionsFilter.find((op) => op.value === filterChart)
        .categories,
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px'
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px'
        },
        formatter: (value) => {
          return formatCurrency(value).replace('VNĐ', '')
        }
      }
    },
    fill: {
      opacity: 1,
      type: 'gradient'
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0
        }
      },
      hover: {
        filter: {
          type: 'none',
          value: 0
        }
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0
        }
      }
    },
    tooltip: {
      style: {
        fontSize: '12px'
      },
      y: {
        formatter: function (val) {
          return formatCurrency(val)
        }
      }
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true
        }
      }
    }
  }
}
